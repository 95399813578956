"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateStyleStringFromVEVars = exports.generateStyleStringFromObject = exports.prepareResponsiveFontSizeRules = void 0;
var dynamic_1 = require("@vanilla-extract/dynamic");
var SMALL_BREAKPOINT = 200;
var LARGE_BREAKPOINT = 768;
var SMALLEST_DESKTOP_SIZE = 16;
var LARGEST_DESKTOP_SIZE = 100;
var SMALLEST_MOBILE_SIZE = 16;
var LARGEST_MOBILE_SIZE = 35;
var SMALLEST_PRINT_SIZE = 12;
var LARGEST_PRINT_SIZE = 60;
var DESKTOP_DIFF = LARGEST_DESKTOP_SIZE - SMALLEST_DESKTOP_SIZE;
var MOBILE_DIFF = LARGEST_MOBILE_SIZE - SMALLEST_MOBILE_SIZE;
var PRINT_DIFF = LARGEST_PRINT_SIZE - SMALLEST_PRINT_SIZE;
var BREAKPOINT_DIFF = LARGE_BREAKPOINT - SMALL_BREAKPOINT;
var prepareResponsiveFontSizeRules = function (cssVar) {
    var _a;
    var smallSize = "".concat(SMALLEST_MOBILE_SIZE, " + (").concat(MOBILE_DIFF, " * (").concat(cssVar, " - ").concat(SMALLEST_DESKTOP_SIZE, "))/").concat(DESKTOP_DIFF);
    var printSize = "calc(".concat(SMALLEST_PRINT_SIZE + PRINT_DIFF, "px * (").concat(cssVar, " - ").concat(SMALLEST_DESKTOP_SIZE, ")/").concat(DESKTOP_DIFF, ")");
    return {
        fontSize: "calc((".concat(smallSize, ") * 1px)"),
        "@media": (_a = {},
            _a["screen and (min-width: ".concat(SMALL_BREAKPOINT, "px)")] = {
                fontSize: "calc((".concat(smallSize, ") * 1px + (").concat(cssVar, " - (").concat(smallSize, ")) * ((100vw - ").concat(SMALL_BREAKPOINT, "px) / ").concat(BREAKPOINT_DIFF, "))"),
            },
            _a["screen and (min-width: ".concat(LARGE_BREAKPOINT, "px)")] = {
                fontSize: "calc(".concat(cssVar, " * 1px)"),
            },
            _a),
        selectors: {
            "#print &, .print-legacy &": {
                fontSize: printSize,
            },
        },
    };
};
exports.prepareResponsiveFontSizeRules = prepareResponsiveFontSizeRules;
var generateStyleStringFromObject = function (style) {
    return Object.entries(style).reduce(function (style, _a) {
        var _b = __read(_a, 2), key = _b[0], val = _b[1];
        return "".concat(style, " ").concat(key, ": ").concat(val, ";");
    }, "");
};
exports.generateStyleStringFromObject = generateStyleStringFromObject;
var generateStyleStringFromVEVars = function (vars) {
    return (0, exports.generateStyleStringFromObject)((0, dynamic_1.assignInlineVars)(vars));
};
exports.generateStyleStringFromVEVars = generateStyleStringFromVEVars;
